import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { StoryData } from 'storyblok-js-client';
import Layout from '../sb-components/layout';
import Page from '../sb-components/page';
import { CommonData, GlobalData } from '../common-types';
import StoryblokService from '../utils/storyblok-service';

interface State {
  pageData: CommonData;
  globalData: GlobalData;
}

interface Props
  extends PageProps<{
    pageData: StoryData<string>;
    globalData: StoryData<string>;
  }> {}

class PrivacyPolicyPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pageData: JSON.parse(props.data.pageData.content),
      globalData: JSON.parse(props.data.globalData.content),
    };
  }

  async componentDidMount() {
    try {
      const response = await StoryblokService.get(`cdn/stories/privacy-policy`);
      this.setState((prevState) => ({
        ...prevState,
        pageData: { ...response.data.story.content },
      }));
      setTimeout(() => StoryblokService.initEditor(this), 200);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <Layout
        className="page page-privacy-policy"
        header={this.state.globalData.header}
        footer={this.state.globalData.footer}
        showBlock
        cookiesConsent={this.state.globalData.cookiesConsent}
        full_slug={this.props.data.pageData.full_slug}
      >
        <Page
          blok={{
            ...this.state.pageData,
          }}
        />
      </Layout>
    );
  }
}

export default PrivacyPolicyPage;

export const query = graphql`
  {
    pageData: storyblokEntry(slug: { eq: "privacy-policy" }) {
      name
      content
      full_slug
      uuid
    }
    globalData: storyblokEntry(full_slug: { eq: "global" }) {
      name
      content
      full_slug
      uuid
    }
  }
`;
